import React from "react";
import style from "./index.module.scss";

export default function Index() {
  return (
    <section>
      <div className={style.holdAll1}>
        <div className={style.heroImage}>
          <div>
            <h1>Testimonial</h1>
          </div>
        </div>
        <div className={style.listOfVideo}>
          <div>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/qnRxRuxRW3M?si=6gpWw_3MrvGoEVa4"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/eR63qOMrE58?si=XeKlDKUdjma2ngUF"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/xWDbdxTAzsY?si=VEyNmCDjazscu1PW"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/GIWxjBs58uA?si=Q-Wq1nAUuHnN26YS"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/3RuYVrEBdAU?si=9qWrQKdd8pb_60Dm"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/UwiD9rkcYuc?si=o5yUd1DiVdTcuX98"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/kZHhJb5SCSY?si=bOKQyoWP0gx8-l5-"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/8zDgxqIdnYQ?si=_SGDgNDs-4DH_Cfy"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/UPDTo2YaylA?si=SUzyykIS1DMzKiTD"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/LYxCJ3Mx_cE?si=INyah8S7LnnIXoiE"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/1WqqtebAQ9M?si=UUnWRcQ3xh3nz_ZG"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="w-full h-full md:h-[350px]"
              src="https://www.youtube.com/embed/Hj9OBzm6P7M?si=j9TUE9-KAgiQNFIu"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div className={style.reviewNow}>
          <a
            href="https://g.page/r/CV3qkQ3EWmOwEBE/review "
            target="_blank"
            rel="noreferrer"
          >
            Please Drop a Review
          </a>
        </div>
      </div>
    </section>
  );
}
