import React, { useState, useEffect } from 'react';
import { IoCall } from 'react-icons/io5';
import NONEM from "./NONEM.module.scss";
import NonBoardofDirectors from '../../../DB/NonBoardofDirectors';
import { Modal } from "react-bootstrap";

function NONNONEMS(props) {
    const [num, setNum] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setNum(index);
        setShow(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <section className={NONEM.holdALl}>
        <div>
          <div className={NONEM.holdAllTeamText}>
            <div
              className={NONEM.holdAllTeamText1}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <h5>The Management Team</h5>
              <h3>Meet the Meet the Management at Zylus Homes</h3>
              <p>
                To be the company our customers want us to be, it takes an
                electric group of passionate operators. Meet the Team leading
                the way at Zylus Homes
              </p>
            </div>
          </div>

          <div className={NONEM.holdAllExecutiveDirectors}>
            <div className={NONEM.holdAllExecutiveDirectors2}>
              {/* <h4>Executive Directors</h4> */}
              <div className={NONEM.holdAllProfileKoko}>
                {NonBoardofDirectors.map((item, index) => {
                  return (
                    <div
                      className={NONEM.holdAllProfileKoko2}
                      key={index}
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                    >
                      <div className={NONEM.holdImage}>
                        <img src={item.image} alt="Team1" />
                      </div>
                      <div className={NONEM.holdText}>
                        <div className={NONEM.holdText2}>
                          <h5>{item.name}</h5>
                          <p>{item.position}</p>
                          <p>{item.member}</p>
                          <button
                            onClick={() => {
                              handleShow(index);
                            }}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={NONEM.holdAnytime}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div>
            <h1 className={NONEM.Reach}>You Can Reach Us Anytime</h1>
            <p className={NONEM.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <button className={NONEM.callUs}>
            <IoCall />
            Call us
          </button>
        </div>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Body className={NONEM.modalBodyV}>
            <div className={NONEM.NonBoardofDirectors}>
              <div className={NONEM.NonBoardofDirectors2}>
                <img src={NonBoardofDirectors[num].image} alt="Team1" />
              </div>
              <div className={NONEM.NonBoardofDirectors3}>
                <p>{NonBoardofDirectors[num].profile1}</p>
                <p>{NonBoardofDirectors[num].profile2}</p>
                <p>{NonBoardofDirectors[num].profile3}</p>
                <p>{NonBoardofDirectors[num].profile4}</p>
                <p>{NonBoardofDirectors[num].profile5}</p>
                <p>{NonBoardofDirectors[num].profile6}</p>
                <p>{NonBoardofDirectors[num].profile7}</p>
                <p>{NonBoardofDirectors[num].profile8}</p>
                <p>{NonBoardofDirectors[num].profile9}</p>
                <p>{NonBoardofDirectors[num].profile10}</p>
                <p>{NonBoardofDirectors[num].profile11}</p>
                <p>{NonBoardofDirectors[num].profile12}</p>
                <p>{[num].profile}</p>
                <button onClick={handleClose} className={NONEM.CloseTas}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
}

export default NONNONEMS;